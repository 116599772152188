

@media screen and (max-width:1440px) {

    .ButtonText.resetButtonBox button.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root.resetButton.css-1v8o3mx-MuiButtonBase-root-MuiButton-root {
        width: 55%;
        position: relative;
        top: 0px;
        margin: 0px 17px;
        text-transform: capitalize;
    }

    .readytogo {
        width: 23%;
        position: relative;
        left: 20px;
    }
    h3.siteStatus{
        margin: 0px 15px 0px 0px;
    }
    h5.accountHead_sub{
        font-size: 30px!important;
    }
    
    .accountHead .subLine{
        width: 35%!important;
    }
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-4.MuiGrid-grid-md-4.MuiGrid-grid-lg-4 .sideImg img {
        height: 266px;
        position: relative;
    }
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-4.MuiGrid-grid-md-4.MuiGrid-grid-lg-4 .sideImg {
        float: left;
        position: relative;
        width: 60%;
        left: -77px;
        top: 0px;
    }
  
 .App .MuiContainer-root.MuiContainer-maxWidthLg .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2 {
        width:80%;
        margin: auto;
    }
    .lineHeight {
        height:225px;
        width: 100%;
        float: left;
    }
    .login_bottom_icon {
        position: absolute;
        left: -60px;
        z-index: -1;
        bottom: -36px;
    }

   
    p.resendLoad {
        position: absolute;
        right: 17px;
        top: -15px;
    }
}

@media screen and (max-width:1280px) {
    .ButtonText.resetButtonBox button.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root.resetButton.css-1v8o3mx-MuiButtonBase-root-MuiButton-root {
        width: 57%;
        position: relative;
        top: 0px;
        margin: 0px 17px;
        text-transform: capitalize;
    }
    .readytogo {
        width: 25%;
        position: relative;
        left: 20px;
    }

    h3.siteStatus{
        margin: 0px 15px 0px 0px;
    }

    .accountHead .subLine{
        width: 35%!important;
    }
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-4.MuiGrid-grid-md-4.MuiGrid-grid-lg-4 .sideImg {
        float: left;
        position: relative;
        width: 60%;
        left: -70px;
        top: 0px;
    }
   
    p.resendLoad {
        position: absolute;
        right: 16px;
        top: -15px;
    }
    .login_left_img img {
        width: 70%;
        float: left;
        margin-top:65px;
    }
}




