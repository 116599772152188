@import "./scss/_variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Overpass:ital,wght@0,100;0,200;0,300;0,400;0,800;0,900;1,100;1,200;1,300;1,400&display=swap");
@import "./scss/_pages/_accountSetup.scss";
@import "./scss/_pages/_setupBrandinglogo.scss";
@import "./scss/_pages/_chooseTheme.scss";
@import "./scss/_pages/_login.scss";
@import "./scss/_pages/_layout.scss";
@import "./scss/_pages/_SiteStatus.scss";
@import "./scss/_pages/_header.scss";
@import "./scss/_pages/_popupBox.scss";
@import "./scss/_pages/_socialMedia.scss";
@import "./scss/_pages/_orginceCateg.scss";
@import "./scss/_pages/_goLive.scss";
@import "./scss/_pages/_previewThemes/previewThemes.scss";
@import "./scss/_pages/_connectDomain.scss";
@import "./scss/_pages/_responsive.scss";
@import "./scss/_pages/_yourAppName/_yourAppName.scss";
@import "./scss/_pages/_userPool/_userPool.scss";
@import "./scss/_pages/_alertBox.scss";
@import "./scss/_pages/_seeDetailsDomain.scss";

@font-face {
  font-family: $font-family-sub-head;
  src: url("../fonts/Barlow-Light.ttf");
}

body {
  margin: 0;
  padding: 0;
  font-family: $font-family-head;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
input {
  font-family: $font-family-head;
}
a {
  text-decoration: none;
}

/* ----------------------------  Start OTP -----------------------------*/
.align_center_otp div {
  justify-content: center !important;
  position: relative;
}

.wrap {
  display: flex;
  justify-content: center;
  bottom: 0;
  width: 100%;
  top: 0;
  margin: auto;
  position: absolute;
  right: 0;
  left: 0;
  align-items: center;
}

.ag-header-group-cell-label,
.ag-header-cell-label {
  display: flex;
  flex: inherit !important;
  overflow: hidden;
  align-items: center;
  text-overflow: ellipsis;
  align-self: stretch;
}
.ag-cell {
  text-align: center;
}
.ag-cell-label-container {
  text-align: center !important;
}
.ag-header-group-cell-label,
.ag-header-cell-label {
  display: block !important;
}
.ag-cell-label-container {
  display: flow-root !important;
}

/* ----------------------------  End OTP -----------------------------*/

/* ----------------------------  Start AccountCreation -----------------------------*/
.line-col {
  width: 1px;
  background-color: #dddddd;
  height: 430px;
  float: right;
  right: 2px;
  position: relative;
}

h2.accountHead {
  margin-top: 50px;
  margin-bottom: 100px;
  font-family: $font-family-head;
  font-style: normal;
  font-size: 36px;
  text-align: center;
  text-transform: uppercase;
  color: $font-color-code;
  font-weight: 400;
  line-height: 43px;
  letter-spacing: 0.1em;
}
h5.accountHead_sub {
  font-family: $font-family-head;
  font-style: normal;
  font-size: 33px;
  line-height: 36px;
  font-weight: 400;
  letter-spacing: 0.1em;
  color: #4a4a4a;
}
p.account_prea {
  font-family: $font-family-sub-head;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.703125px;
  color: #4a4a4a;
}
.clear {
  height: 20px;
}

p.acc_sub {
  font-family: $font-family-sub-head;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 36px;
  color: #a4a4a4;
  white-space: nowrap;
}
span.acc_s {
  color: $font-color-code;
  font-weight: 700;
}

.accordion.linkJan.accCre
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters {
  box-shadow: none;
}

.accordion.linkJan.accCre .MuiButtonBase-root .MuiAccordionSummary-content p {
  letter-spacing: 0.703125px;
  color: $font-color-code;
  font-family: $font-family-sub-head;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding: 0 5px;
}
.janeAcc
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters
  .MuiButtonBase-root.MuiAccordionSummary-root.MuiAccordionSummary-gutters
  .MuiAccordionSummary-content.MuiAccordionSummary-contentGutters {
  width: 100%;
}

p.preContent {
  // font-family: "Overpass";
  // font-style: normal;
  // font-weight: 400;
  // line-height: 24px;
  letter-spacing: 0.703125px;
  // color: #000000;
  opacity: 0.8;
  // font-size: 16px;

  font-family: $font-family-sub-head;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #4a4a4a;
}

a.showme {
  font-family: $font-family-sub-head;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 36px;
  letter-spacing: 0.703125px;
  text-decoration-line: underline;
  color: #00bc6f;
}

.logout-inner svg {
  cursor: pointer;
}
.accountHead .subLine {
  width: 45%;
  height: 9px;
  border-radius: 15.5px;
  display: grid;
  margin: auto;
  background: #000000;
  margin-top: 12px;
}

.active_btn_linkAccountBtm {
  float: right;
  font-family: $font-family-head !important;
  background: #000000 !important;
  border-radius: 4px;
  width: 162px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.2em;
  color: #ffffff !important;
}
.unactive_btn_linkAccountBtm {
  float: right;
  font-family: $font-family-head !important;
  background: $disabled_btn !important;
  border-radius: 4px;
  width: 162px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.2em;
  color: #ffffff !important;
}
.active_btn_next {
  float: right;
  font-family: $font-family-head !important;
  background: #000000 !important;
  border-radius: 4px;
  width: 100px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.2em;
  color: #ffffff !important;
}

.janeAcc img {
  // width: 34%;
  // top: -125px;
  position: absolute;
  float: right;
  right: 33px;
  width: 60px;
  height: 60px;
}
.linkAccountBtm {
  margin-top: 0px !important;
  padding: 8px 24px !important;
}

.ag-center-cols-container {
  width: 100% !important;
}

.sideImg {
  float: left;
  position: relative;
  width: 25%;
  left: 10px;
  top: 4px;
}
.statusProj .btnProgress button {
  position: relative;
  right: 28px;
  top: -4px;
}

.accordion.linkJan.MuiBox-root.css-0
  .css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
  position: absolute;
  left: 0;
  top: -1px;
  right: 0;
  height: 1px;
  content: "";
  opacity: 1;
  background-color: #fff !important;
  -webkit-transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters.linkjane.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  border-top: 1px solid gainsboro;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dbdddf;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.MuiGrid-root.MuiGrid-container.MuiGrid-item.MuiGrid-spacing-xs-3.MuiGrid-grid-md-4.drag_reorder {
  display: flex;
  justify-content: center;
  align-items: center;
  position: initial;
}

.drag_reorder h3 {
  width: 100%;
  text-align: center;
  font-family: "Overpass";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 35px;
  letter-spacing: 0.546875px;
  color: #444444;
}

.drag_reorder {
  float: right;
  width: 120px;
}
.drag_reorder img {
  margin: auto;
  display: flex;
  width: 15px;
}

button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit.MuiIconButton-edgeStart.MuiIconButton-sizeMedium.toggleLeftBar {
  color: $font-color-code;
}

.MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root.socialLink {
  width: 88%;
}

button.MuiButton-root.MuiButton-text {
  color: $font-color-code;
}

.alertDomain svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeInherit {
  display: none;
}

.janeAcc img {
  width: 60%;
  position: absolute;
  float: right;
  right: 75px;
  margin-top: -16px;
}

.wrap {
  display: flex;
  justify-content: center;
  bottom: 0;
  width: 100%;
  top: 0;
  margin: auto;
  position: absolute;
  right: 0;
  left: 0;
  align-items: center;
}

label {
  font-family: $font-family-head;
}

// input {
//   font-family: $font-family;
//   font-weight: 700 !important;
//   font-size: 20px;
//   letter-spacing: 0.7px;
//   line-height: 28.28px;
//   background: #FAF9FA;
//   color: #000;
// }

// span {
//   font-family: $font-family;
// }

// button{font-family: $font-family; margin-top: 30px;}

.animateBox p {
  margin: 6px;
}

.animateBox {
  width: 100%;
  float: left;
  text-align: left;
  display: flex;
}

li {
  list-style-type: none;
}

li.animateBox.animateBox5 {
  margin: 0px -82px;
}

li.animateBox.animateBox4 {
  margin: 0px -82px;
}

li.animateBox.animateBox6 {
  margin: 0px -82px;
}

.janeAcc.MuiBox-root.css-0 {
  margin-bottom: 45px;
}

.css-1jcoppc-MuiLinearProgress-root.MuiLinearProgress-colorPrimary {
  background-color: #ffffff;
}

/* ----------------------------  End AccountCreation -----------------------------*/

/**************************************************** ProgressBar ********************************/

@keyframes progressAnimationStrike {
  from {
    width: 10%;
  }

  to {
    width: 100%;
  }
}

.progress2 {
  padding: 4px;
  border-radius: 30px;
  background: rgba(221, 221, 221, 0.25);
  box-shadow: inset 0 1px 2px rgba(192, 192, 192, 0.25),
    0 1px rgba(255, 255, 255, 0.08);
  width: 75%;
  float: left;
}

.wrapper-ani.animateBox4 {
  position: relative;
}

.wrapper-ani.animateBox4 button {
  position: absolute;
  top: -9px;
  right: 3px;
}

.progress-bar2 {
  height: 14px;
  border-radius: 30px;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.05)
  );
  transition: 0.4s linear;
  transition-property: width, background-color;
}

.progress-moved .progress-bar2 {
  width: 100%;
  background-color: #000000;
  animation: progressAnimation 6s;
}

@keyframes progressAnimation {
  0% {
    width: 0%;
    background-color: #000000;
  }

  5% {
    width: 5%;
    background-color: #000000;
  }

  10% {
    width: 5%;
    background-color: #000000;
  }
  20% {
    width: 20%;
    background-color: #000000;
  }
  30% {
    width: 30%;
    background-color: #000000;
  }
  40% {
    width: 40%;
    background-color: #000000;
  }

  50% {
    width: 50%;
    background-color: #000000;
  }
  60% {
    width: 60%;
    background-color: #000000;
  }

  70% {
    width: 70%;
    background-color: #000000;
  }

  80% {
    width: 90%;
    background-color: #000000;
  }
  75% {
    width: 75%;
    background-color: #000000;
  }
  85% {
    width: 85%;
    background-color: #000000;
  }
  90% {
    width: 90%;
    background-color: #000000;
  }
  60% {
    width: 60%;
    background-color: #000000;
  }
  80% {
    width: 80%;
    background-color: #000000;
  }

  10% {
    width: 0%;
    background-color: #000000;
  }

  50% {
    width: 50%;
    background-color: #000000;
  }

  100% {
    width: 100%;
    background-color: #000000;
  }
}

/**************************************************** ProgressBar ********************************/
/**************************************************Start Side Bar *************************************/

input[type="color"] {
  width: 100px;
  height: 100px;
  border-radius: 5%;
  overflow: hidden;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: 5%;
  padding: 0;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  border: none;
  border-radius: 5%;
  padding: 0;
}

.contentBox {
  float: right;
  right: 38px;
  width: calc(86% - 76px);
  position: relative;
  top: 74px;
}

// p.MuiTypography-root.MuiTypography-body1.css-ahj2mt-MuiTypography-root {
//   font-weight: 700;
//   font-size: 16px;
//   padding-left: 35px;
// }
.accordion.MuiBox-root.css-1pfqu1g {
  width: 100% !important;
}

.MuiGrid-root.MuiGrid-grid-sm-6.css-o7llqo-MuiGrid-root {
  text-align: left;
}

.setup_btn {
  float: right;
}

// .logout{
// margin: 0 12px;
// }
// .rightside{
// float: right; background-color: red; height: 12px;
// width: 33%;
// }

.navwrap {
  width: 100%;
  float: left;
}

.inner_group_1_header {
  float: left;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-inner {
  float: left;
  width: 60%;
  text-align: left;
}

.btn-inner {
  float: left;
  width: 40%;
}

.btn-inner Button {
  background: #8a8a8a !important;
  width: 100%;
}

.progressbar-inner {
  width: 40%;
  float: left;
}

.inner_group_2_header {
  float: right;
  width: 175px;
  position: relative;
}

.inner_group_2_header .logout-inner {
  float: right;
  align-items: center;
  justify-content: center;
  display: flex;
}

.progressbar-main {
  display: flex;
  justify-content: center;
  width: 75%;
  align-items: center;
}

a.active p {
  font-weight: 700;
  color: #1a1a1a;
}

@media screen and (max-width: 768px) {
  /* ----------------------------  Start Mobile Login -----------------------------*/

  .popupBox {
    width: 90%;
  }

  .line-col {
    display: none;
  }

  .css-mx21h1-MuiFormLabel-root-MuiInputLabel-root {
    z-index: -1 !important;
  }

  .leftBar {
    max-width: 200px;
    position: absolute;
    top: 88px;
  }

  .contentBox {
    position: relative;
    float: left;
    width: 96%;
    margin: 9px 16px;
  }

  /* ----------------------------  End Mobile Login -----------------------------*/
  .janeAcc img {
    top: -84px;
    right: 80px;
  }
}

@media screen and (max-width: 480px) {
  .popupBox {
    width: 90%;
  }

  li.animateBox.animateBox4 {
    margin: 0;
  }

  li.animateBox.animateBox5 {
    margin: 0;
  }

  li.animateBox.animateBox6 {
    margin: 0;
  }

  .progress_bar {
    width: 50%;
    float: left;
  }
}

/**************************************************Start Side Bar *************************************/
ul.animate-box {
  float: left;
}

ul.animate-box li h1 {
  float: left;
}

.checkmark {
  stroke: green;
  stroke-dashoffset: 745.7485351563;
  stroke-dasharray: 745.7485351563;
  -webkit-animation: dash 2s ease-out forwards 1;
  animation: dash 2s ease-out forwards 1;
}

.content_ani {
  width: 100%;
  font-size: 18px;
  text-align: left;
}

h4.MuiTypography-root.MuiTypography-h4.MuiTypography-alignLeft.css-1xaqvpl-MuiTypography-root {
  width: 45%;
}

.box_svg {
  width: 25px;
  float: left;
  position: relative;
  margin: 0px 15px;
}

.content_ani p {
  padding: 0;
  margin: 0;
}

.wrapper {
  width: 100%;
  float: left;
  margin-bottom: 10px;
}

.wrapper-ani {
  float: left;
  width: 94%;
}

// p {
//   font-family: sans-serif;
//   color: pink;
//   font-size: 30px;
//   font-weight: bold;
//   margin: 20px auto;
//   text-align: center;
//   animation: text .5s linear .4s;
//   -webkit-animation: text .4s linear .3s;
// }


.accordion.linkJan.accCre .MuiButtonBase-root .MuiAccordionSummary-content p {
  letter-spacing: 0.703125px;
  color: #000000;
  font-family: "Barlow";
  font-style: normal;
  width: 230px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding: 0 5px;
}
@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 745.7485351563;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 745.7485351563;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

.accordion.setupacc .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 0px;
  box-shadow: 0px 0px 0px 0px black, 0px 0px 1px 0px black,
    0px 0px 0px 0px black !important;
  position: relative;
}

button.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButtonBase-root.Mui-disabled.active_btn.linkaccount.css-3ssovo-MuiButtonBase-root-MuiButton-root {
  margin-top: 0 !important;
}

.setup_btn.account-setup {
  font-family: $font-family-head !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 22px !important;
  line-height: 26px !important;
  letter-spacing: 0.2em !important;
  border-radius: 4px !important;
  padding: 15px !important;
  position: relative !important;
  padding-top: 16px !important;
  cursor: pointer !important;
}

img.arrowbtn {
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  margin: auto !important;
}
