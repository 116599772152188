button.siteBtn svg {
  position: absolute;
}

button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButtonBase-root.siteBtn svg {
  position: absolute;
  left:4px;
  font-size: 8px;
  top: 12px;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.brightNotLive {
  position: absolute;
  left: 2px;
  font-size: 11px;
  background:$font-background-code;
border-radius: 25px;
  top: 3px;
}
.siteBtn {
  width: 130px;
}
.stackLeft button {
  background: grey;
}

.stackLeft .enable {
  background:$font-background-code-white;
}

.greenIcon {
  position: absolute;
  left: 2px;
  font-size: 11px;
  color: rgba(0, 188, 111, 1) !important;
  top: 3px;
}

.stackLeft {
  float: left;
  margin: 2px 8px;
}

.siteRef {
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e0e0e0;
  padding: 6px;
}

.forRefRight {
  float: left;
}

p.MuiTypography-root.MuiTypography-body1.siteLbl {
  font-family: $font-family-sub-head;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  margin: 0px 8px;
  letter-spacing: 0.1em;
}

h3.siteStatus {
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.703125px;
  color:$font-color-code;
  text-transform: uppercase;
}

.siteStatusBox {
  float: left;
  width: 100%;
  justify-content: left;
  align-items: center;
  display: flex;
}

p.MuiTypography-root.MuiTypography-body1.siteLbl.completeSite {
  font-size: 16px !important;
}


button.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButtonBase-root.Mui-disabled.setup_btn {
  background: $font-background-code;
  font-family:$font-family-head;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;
  color:$font-color-code-white;
}

button.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButtonBase-root.setup_btn.account-setup.css-rm1uqm-MuiButtonBase-root-MuiButton-root {
  background:$font-background-code;
  border-radius: 3px;
  color:$font-color-code-white;
  font-family:$font-family-head;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;
  color:$font-color-code-white;
}

.siteStatusRight button.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root.prev {
padding: 10px;
gap: 10px;
width:48%;
text-transform: capitalize;
height: 40px;
border: 1px solid $border-color-code;
border-radius: 3px;
font-family:$font-family-head;
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 24px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.1em;
color:$font-color-code;
background: transparent;
}

.siteStatusRight {
position: relative;
display: flex; justify-content: center; padding-bottom: 12px;
}
button.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButtonBase-root.Mui-disabled.setup_btn.theme_btn.css-1o4rz3q-MuiButtonBase-root-MuiButton-root {
  background:$font-background-code;
  color:$font-color-code-white;
  text-transform: uppercase;
}
.siteStatusRight button {
  width:48%;
  padding: 10px;
  gap: 10px; margin:0px 8px;
  height: 40px;
  background: #00BC6F;
  border-radius: 5px;
  background:$font-background-code;
  border: 1px solid $border-color-code;
    border-radius: 3px;
  flex: none;
  order: 1;
  flex-grow: 0;
  text-transform: capitalize;
}
.siteStatusRight button.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButtonBase-root.setup_btn.theme_btn.goliveBtn {
text-transform: uppercase;
font-family:$font-family-head;
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 24px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.1em;
color:$font-color-code-white;
}
.siteStatusRight button.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButtonBase-root.setup_btn.theme_btn.goliveBtn:hover{
  background-color:$font-background-code;
}
button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButtonBase-root.siteBtn.Complbl.css-9u7qc3-MuiButtonBase-root-MuiButton-root{
  border: transparent;
}
button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButtonBase-root.siteBtn.siteStatusBtn p{
font-family:$font-family-sub-head;
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 19px; text-transform: capitalize;
text-align: center;
letter-spacing: 0.1em;
color: #FF0000;
&.-live{
  color:green;
}
&.-incomplete{
  color:#FFAB2D; display: contents;
}
}
button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButtonBase-root.siteBtn.Complbl.css-9u7qc3-MuiButtonBase-root-MuiButton-root p{
color:#FFC220;
}
button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButtonBase-root.siteBtn.Complbl.css-9u7qc3-MuiButtonBase-root-MuiButton-root svg{
  border: none;
  width: 10px; color:#FFC220;
  }

  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    background: transparent;
    box-shadow: none;
}
button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButtonBase-root.siteBtn.siteStatusBtn {
border: transparent; 
cursor:default;
width: 200px;
}
.stackRight button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButtonBase-root.siteBtn.siteStatusBtn:hover {
  background:transparent;
}


button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButtonBase-root.siteBtn.siteStatusBtn p {
  border: transparent; color: $font-color-code;
}

button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButtonBase-root.greenLive.css-4o56ty-MuiButtonBase-root-MuiButton-root{
  border: none;
}
button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButtonBase-root.greenLive.css-4o56ty-MuiButtonBase-root-MuiButton-root svg {
border: none;
width: 10px;
}

button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButtonBase-root.greenLive.css-4o56ty-MuiButtonBase-root-MuiButton-root p {
  font-family:$font-family-sub-head;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  margin: 0px 8px;
  letter-spacing: 0.1em;
  color: #00BC6F;
}

.skipbtnRight.MuiBox-root button span {
  margin-top: 3px;
}

.siteStatus {
  float: left;
  width: 32%;
}
.stackRight {
  float:left;
  width: 100%;
}

.domainlbl::-webkit-input-placeholder {
  font-family:$font-family-sub-head;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.1em; 
  color:$font-placeholder-color-code;  
}

.domainlbl ::-moz-placeholder {
  font-family:$font-family-sub-head;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.1em;
  color:$font-placeholder-color-code;  
  
}

.domainlbl ::-moz-placeholder {
  font-family:$font-family-sub-head;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.1em; 
  color:$font-placeholder-color-code;  
}

.domainlbl :-ms-input-placeholder {
  font-family:$font-family-sub-head;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.1em; 
  color:$font-placeholder-color-code;  
  }

.domainlbl label {
  font-family:$font-family-sub-head;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.1em; 
  color:$font-placeholder-color-code;  
}


