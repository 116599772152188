

.previewThemes .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
    max-width: 100%;
    background: #FFF;
    max-width: 100%!important;
    flex-direction:none;
    width: 100%; height:100%;
    margin: 0;
    max-height: calc(100% - 0px);
    border-radius: 0px;
}

.loadrPart {
    width:100%;
    display: block;
    justify-content: center;    
    text-align: center;
    align-items: center;
    right: 0;
    left: 0;
    bottom: 0;
}
.loadrPart img.loaderImg {
    margin-top: 150px;
}

.previewThemes_header{
width: 100%; 
z-index: 1;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

}
.previewThemes_header header {
    width: 100%;
    background-color: #f1f5f9;
    float: left;
    border-bottom: 1px solid #CDCDCD;
    position: fixed;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
   
}
.previewThemes_header svg {
    margin: 4px;
}
.previewThemes_header button {
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    color: #000000;
    text-transform: capitalize;
}

.listPage button.MuiTypography-root.MuiTypography-body2.MuiLink-root.MuiLink-underlineAlways.MuiLink-button.active {  
    color: #000;
    background: #F1F1F1;
    border-radius: 4px;
}
button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorSecondary.Mui-selected {
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    color: #000000;
    text-transform: capitalize;
}
button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorSecondary {
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    color: #000000;
    text-transform: capitalize;
}
.SideTab {
    width: 18%;
    float: left;
    background: #fff;
    position: fixed;
    bottom: 0;
    height: 100%;
    top: 60px;
    border-right: 1px solid #CDCDCD;
    padding: 20px 0;
}

.Themescontainer .MuiGrid-root.MuiGrid-item {
    width:81%;
    float: right;
    display: flex;
    align-items: center;
    justify-content:right;
}
.Themescontainer .MuiGrid-root.MuiGrid-item .prevImg_show img {
    width:100%;
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;
}

.listPage button {
float: left;
width: 100%;
padding:12px 5px;
text-align: left;
text-decoration: none;
justify-content: left;
align-items: center;
position: relative;
display: flex;
}
.listPage button p {
font-family: 'Bebas Neue';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 24px;
display: flex;
align-items: center;
letter-spacing: 0.1em;
color: #000000;
}

.listPage img {
    float: left;
}
.listPage img {
    float: left;
    margin: 0px 10px;
}
.preview_active p {
height: 30px;
font-family: 'Potta One';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 36px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.703125px;
color: #FFFFFF;
text-shadow: 0px 4px 4px rgb(0, 0, 0 / 25%);
}
.themesimg{
    background: url('../../../../public/assets/images/1.png')  #cfcfe6;;
    
    background-size: cover;
    height: 100px;
    width: 100%;
    float: left; position: relative;
    background-repeat: no-repeat; cursor: pointer;
}
.themesimg:after {
    position: absolute;
    content: " ";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-color: rgba(241, 245, 249, 0.4);
  
  }

.theme_effects2img{
    background: url('../../../../public/assets/images/2.png');
    background-size: cover;
    height: 100px;
    width: 100%;
    float: left; position: relative;
    background-repeat: no-repeat; cursor: pointer;
}
.theme_effects2img:after {
    position: absolute;
    content: " ";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-color: rgba(241, 245, 249, 0.4);
  
  }

.theme_effects3img{
    background: url('../../../../public/assets/images/3.png');
    background-size: cover;
    height: 100px;
    width: 100%;
    float: left; position: relative; cursor: pointer;
    background-repeat: no-repeat;
}

.themes_center div p {
    position: absolute;
    margin: auto;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    bottom: 0;
    top: 0;
    color:#000;
    right: 0;
    z-index: 111;
    left: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;

}
.theme_effects3img:after {
    position: absolute;
    content: " ";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-color: rgba(241, 245, 249, 0.4);
  
  }
  .preview_active p {
    background: rgba(0, 188, 111,0.8);
    color: #FFF!important;
}
.previewMode {


    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.1em;
    color: #000000;
    left: 10px;
    position: relative;
    margin-top: 13px;
    align-items: center;
    text-align: center;
    float: left;
    letter-spacing: 0.703125px;
    color: #000000;
    text-transform: capitalize;
}

.prevImg_show {
    display: flex;
    justify-content: center;
    align-items: center; width:100%; float: right;
}
.remove_padding{
    padding-right: 0!important;
}
.main{
position: fixed;
background: #00000050;
width: 100%;
height: 100vh;
top: 0;
left: 0;
}

.prevImg_show {
    display: flex;
    justify-content: right;
    align-items: center;
    width: 100%;
    margin-top: 65px;
}
.prevImg_show img {
width:100%; float: right;
}

.box{
    position: relative;
    width: 70%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
}
