.acc_setup_main .acc_cred_grid {
  text-transform:$font-uppercase;
  font-weight: 300;
  letter-spacing: 0.7px;
  font-size: 14px;
}
.acc_setup_main .acc_cred_ans {
  font-size: 16px;
  color:$font-color-code;
  font-weight: 400;
  letter-spacing: 0.7px;
  line-height: 20px;
}
.divider {
  width: 100%;
}
.Button_active {
  float: right;
  background-color:$font-background-code;
  text-transform: none;
}
.acc_setup_head h3 {
  font-family: $font-family-head;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  color:$font-color-code;
}
.acc_cust_head {
  font-size: 13px;
  font-weight: 700;
}
.acc_credentials {
  margin-top: 25px;
}
.acc_credentials_sub {
  margin-top: 5px;
}
.MuiAccordionDetails-root.css-15v22id-MuiAccordionDetails-root {
  border: 1px solid #f3f3f3;
}
.iconCheck img {
  position: absolute;
  width: 18px;
}
.iconCheck {
  float: left;
  left: -8px;
  position: relative;
  width: 22px;
}
button.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButtonBase-root.Mui-disabled.active_btn.linkAccountBtm {
  background: #cccccc !important;
  border: 1px solid #cccccc !important;
}
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-md-5.acc_cred_ans.acc_cust_head.css-yw6hac-MuiGrid-root {
  word-break: break-all;
}

.contentBox
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.Mui-expanded.MuiAccordion-gutters.accordion_border {
  border: 1px solid $border-color-code;
  border-radius: 4px;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.Mui-expanded.MuiAccordion-gutters.accordion_border.border_none
  .MuiButtonBase-root {
  border: none;
}

.skipbtnRight
  button.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root.css-g240z0-MuiButtonBase-root-MuiButton-root {
  float: right;
  position: relative;
  right: 0;
}

span.skipspan {
  text-transform: capitalize;
  font-family: "Overpass";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #381e47;
  opacity: 0.7;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.Mui-expanded.MuiAccordion-gutters.accordion_border.border_none
  .MuiAccordionDetails-root {
  border: none !important;
}

.setupBrandBtn {
  position: absolute;
  right: 0;
}

.accordion.linkJan.accCre
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters::before {
  background: transparent;
}
p.acc_header {
  font-family: $font-family-head;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  color:$font-color-code;
}
.acc_cred_grid h3 {
  font-family: $font-family-head;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  color:$font-color-code;
}
.acc_cred_ans.acc_cust_head h3 {
  font-family: $font-family-sub-head;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  color:$font-color-code;
  word-break: break-all;
}
.acc_cred_ans.acc_cust_head.medicalOnly h3 {
  text-transform:$font-uppercase;
}

.contentBox h5 {
  font-family: $font-family-head;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 43px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  color:$font-color-code;
}
.accordion.linkJan .MuiAccordionSummary-expandIconWrapper {
  position: relative;
  margin-top: -12px;
}

p.MuiTypography-root.MuiTypography-body1.account_Head.css-ahj2mt-MuiTypography-root {
  padding: 0;
  font-weight: 400;
  color:$font-color-code;
}

.btnColor {
  margin: auto;
  width: 124px;
  color:$font-color-code-white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #444444;
  text-align: center;
  text-transform: none;
  cursor: pointer;
  padding-bottom: 3px;
  padding-top: 3px;
}

.accordion_border .MuiAccordionSummary-root:before {
  background-color:$font-background-code-white !important;
}
.accordion_border:before {
  background-color: $font-background-code-white !important;
}

.accordion_border .MuiAccordionSummary-root {
  border: 1px solid $border-color-code;
  background:$font-background-code-white;
  border-bottom: 4px solid $border-color-code;
  height: 86px;
  border-radius: 4px;
}
.MuiAccordionSummary-expandIconWrapper {
  width: 41px;
  height: 41px;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
}

.MuiAccordionSummary-expandIconWrapper svg {
  color:$font-color-code;
}

.MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  width: 13px;
  height: 2px;
  float: right;
  background-image: url(../../../public/assets/images/minus.svg) !important;
  position: relative;
  right: 4.8px;
  top: -8px;
  background-color:$font-background-code;
}

a.active p {
  font-weight: 700;
  color: #1a1a1a;
}

h3.MuiTypography-root.MuiTypography-h3.css-181191u-MuiTypography-root {
  font-size: 20px;
}

.MuiAccordionSummary-expandIconWrapper.Mui-expanded svg {
  display: none;
}

.MuiPaper-root.MuiPaper-elevation.accordion_border {
  box-shadow: none !important;
}
.popupBox .MuiGrid-root.MuiGrid-root h3 {
  font-family: $font-family-head;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.703125px;
  color:$font-color-code;
  padding: 10px 0px;
}
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-3.MuiGrid-grid-md-3.MuiGrid-grid-lg-1.block-line-col {
  position: relative;
}
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-3.MuiGrid-grid-md-3.MuiGrid-grid-lg-1 .line-col {
  position: absolute;
  right: 25px;
}

.validate_acc_creation {
  position: relative;
}
.validate_acc_creation p {
  position: absolute;
  color: red;
  margin: 0;
  padding: 5px 0px;
}
.sideImg img {
  height: 327px;
  position: relative;
}
.reduce_height {
  min-height: 64px !important;
  height: 65px !important;
}
