.oneDialog {
    width:100%; float: left;
}

.twoDialog {
    width:100%; float: left;
}
.oneDialog h1 {
align-items: center;
display: flex;
justify-content: center;
font-family: $font-family-head;
font-style: normal;
font-weight: 700;
font-size: 40px;
line-height: 36px;
text-align: center;
color:$font-color-code;
padding-bottom: 20px;
padding-top: 16px;
}
.twoDialog h1 {
align-items: center;
display: flex;
justify-content: center;
font-family: $font-family-head;
font-style: normal;
font-weight: 700;
font-size: 40px;
line-height: 36px;
text-align: center;
color:$font-color-code;
padding-bottom: 20px;
padding-top: 16px;
}


.oneDialog p {
align-items: center;
display: flex;
justify-content: center;
font-family:$font-family-sub-head;
font-style: normal;
font-weight: 400;
font-size:24px;
line-height:20px;
text-align: center;
color:$font-color-code;
}
.twoDialog p {
align-items: center;
display: flex;
justify-content: center;
font-family:$font-family-sub-head;
font-style: normal;
font-weight: 400;
font-size:24px;
line-height:20px;
text-align: center;
color:$font-color-code;

}
.two_imgDialog {
    padding-bottom: 18px;
    padding-top: 12px;
}


.one_imgDialog {
    padding-bottom: 18px;
    padding-top: 12px;
}
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-md-2.skipConnect {
    margin-top: 16px;
}

.one_imgDialog img {
    width:40%;
    display: flex;
    justify-content: center;
    align-items: center; 
    margin: auto;
    position: relative;
    bottom: 0;
}
.AnimateBox {
    background-image: url('../../../public/assets/images/main/over_Box.png');
    width:43%;
    background-size: cover;
    background-repeat: no-repeat;
    height:218px;
    margin: auto; margin-top: 12px;
  }
  .two_imgDialog img {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    position: relative;
    bottom: 0;
    margin-top: 55px;
}
.MuiDialogContent-root.goingLive{
    border-radius: 20px; background:$font-background-code-white;
    box-shadow: none!important;
}