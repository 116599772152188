@import "../../common.scss";

.centeringImage {
  position: absolute;
  margin: auto;
  bottom: 0;
  left: 0;
  right: 0;
}
.centeringText {
  justify-content: center;
  display: inline-flex;
  padding-top: get-vw(100px);
}
.makingRelative {
  position: relative;
  text-align: center;
}

.fullheight {
  height: 100vh;
  background: radial-gradient(
    84.86% 84.86% at 68.95% 88.43%,
    #55ffb8 0%,
    #55ffe0 100%
  );
}

.imageResolution {
  width: get-vw(268px);
  height: get-vw(221px);
}

.createCss {
  font-family: "Bebas Neue";
  font-style: normal;
  font-weight: 400;
  font-size: get-font-vw(39px);
  line-height: get-font-vw(47px);
  letter-spacing: 0.05em;
  color: #ffffff;
  max-width: get-vw(338px);
  text-align: center;
}
