.popupBox {
  width: 45%;
  border-radius: 12px;
  padding-bottom: 12px;
  padding-top: 12px;
  height: 350px;
}
.progressBarMoved {
  float: left;
  width: 95%;
}
.progress2.progress-moved {
  float: left;
  width: 65%;
  margin: 4px 20px;
}

.btnProgress button {
  margin: 5px;
  margin-top: 0 !important;
  padding: 5px 0px 5px;
  font-family: "Overpass";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.main {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}
.box {
  position: relative;
  width: 70%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background:$font-background-code-white;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
}
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #6cd038;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: block;
  position: relative;
  stroke-width: 2;
  stroke: #6cd038;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #6cd038;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #fff;
  }
}

.firstLoad h2 {
  float: left;
}
.loadingAnimate {
  float: left;
  width: 100%;
}
.Tri {
  float: right;
}
.firstLoad {
  float: left;
  width: 100%;
  position: relative;
}
._name {
  float: left;
}
.nameAddress p {
  float: left;
  position: relative;
  text-align: left;
  margin: 5px 0px;
  animation: fadeIn1 5s;
  font-family:$font-family-sub-head;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.703125px;
  color:$font-color-code;
  font-size: 16px;
}
._name p {
  float: left;
  position: relative;
  text-align: left;
  margin: 5px 0px;
  animation: fadeIn1 5s;
}

.secondLoad {
  float: left;
  width: 100%;
  position: relative;
}
.thirdLoad {
  float: left;
  width: 100%;
  position: relative;
}
.nameAddress1 p {
  float: left;
  position: relative;
  text-align: left;
  margin: 5px 0px;
  animation: fadeIn1 5s;
  font-family:$font-family-sub-head;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.703125px;
  color:$font-color-code;
  font-size: 16px;
}
._name1 p {
  float: left;
  position: relative;
  text-align: left;
  margin: 5px 0px;
  animation: fadeIn1 5s;
}
.nameAddress2 p {
  float: left;
  position: relative;
  text-align: left;
  margin: 5px 0px;
  font-size: 16px;
  animation: fadeIn1 5s;
  font-family:$font-family-sub-head;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.703125px;
  color:$font-color-code;
}
._name2 p {
  float: left;
  position: relative;
  text-align: left;
  margin: 5px 0px;
  animation: fadeIn1 5s;
}

.blockLine {
  width: 100%;
  clear: both;
}

.loadG {
  height: 60px;
  float: left;
  width: 70px;
}

.lineStatus {
  width: 3px;
  height: 15px;
  background:$font-background-code;
  position: absolute;
  left: 18px;
  top: 42px;
}
.loadersvg {
  height: 60px;
  float: left;
  width: 70px;
}

.loaderGif img {
  width: 74%;
  position: absolute;
  top: 0;
  margin: auto;
  bottom: 0;
  left: 0;
  right: 0;
}

.loaderGif {
  width: 40px;
  height: 40px;
  border: 1px solid #d0d0d0;
  border-radius: 100px;
  float: left;
  position: relative;
}
.FourLoad {
  animation: fadeIn 8s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.successfully_import {
  float: left;
  font-size: 12px;
  animation: fadeIn1 5s;
  font-family: "Overpass";
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.703125px;
  color:$font-color-code;
  position: relative;
  left: 65px;
}

.btnProgress button {
  animation: buttonfadeIn 8s;
}
@keyframes buttonfadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn1 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.successFullBox {
  float: left;
  width: 100%;
  position: relative;
  height: 30px;
}
.statusHeight {
  float: left;
  position: relative;
  min-height: 180px;
}

.FourLoad_nu1 {
  animation: loadFour 5s;
  font-size: 14px;
}
@keyframes loadFour {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 5;
  }
}

.FourLoad_nu2 {
  animation: loadFour 5s;
  font-size: 14px;
}
@keyframes loadFour {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 5;
  }
}

.FourLoad_nu3 {
  animation: loadFour 5s;
  font-size: 14px;
}
@keyframes loadFour {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 5;
  }
}
.FourLoad {
  animation: fadeIn 8s;
  font-family:$font-family-head;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 36px;
  display: flex;
  align-items: center;
  letter-spacing:2px;
  color:$font-color-code;
  position: absolute;
  left: 90px;
}

.statusProj {
  position: absolute;
  width: 80%;
  right: 5px;
}
