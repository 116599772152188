.socialMedia ::-webkit-input-placeholder {
  font-family:$font-family-sub-head;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.1em;
  color:$font-placeholder-color-code;
}
.socialMedia :-moz-placeholder {
  font-family:$font-family-sub-head;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.1em;
  color:$font-placeholder-color-code;
}
.socialMedia ::-moz-placeholder {
  font-family:$font-family-sub-head;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.1em;
  color:$font-placeholder-color-code;
}
.socialMedia :-ms-input-placeholder {
  font-family:$font-family-sub-head;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.1em;
  color:$font-placeholder-color-code;
}
.socialMedia label#outlined-helperText-label {
  font-family:$font-family-sub-head;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.1em;
  color:$font-placeholder-color-code;
}
.alertBox {
  padding: 16px !important;
  background: #fafafa !important;
  border: 1px solid #dddddd !important;
  border-radius: 5px !important;
  font-family:$font-family-sub-head !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  letter-spacing: 0.1em !important;
  color:$font-color-code !important;
}
.alertBox p {
  font-family:$font-family-sub-head;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.1em;
  margin: 5px;
  color:$font-color-code;
}

.alertBox h3 {
  font-family:$font-family-head;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  margin: 5px;
  letter-spacing: 0.1em;
  color:$font-color-code;
}

.alertStack
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.MuiAlert-root.MuiAlert-standardSuccess.MuiAlert-standard {
  background: #f1f5f9;
  border-radius: 5px;
}

.socialMedia p.MuiFormHelperText-root {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.1em;
  color:$font-color-code;
}
.alertStack
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.MuiAlert-root.MuiAlert-standardSuccess.MuiAlert-standard {
  position: relative;
  left: 17px;
  margin-bottom: 30px;
}

.alertStack
  p.MuiTypography-root.MuiTypography-body1.alertBox.css-ahj2mt-MuiTypography-root
  p {
  font-family: "Overpass";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 30px;
  color: #777777;
  text-transform:$font-uppercase;
}
