.effectImg.img {
  border: 2px solid $border-color-code;
  width: 100%;
  padding-bottom: 12px;
}
.categoryImg.img {
  border: 2px solid $border-color-code;
  width: 100%;
  padding-bottom: 0px;
}
.categoryImg.img p.prea {
  width: 65%;
  margin: auto;
}
.effectImg.img p.prea {
  width: 65%;
  margin: auto;
}
.effectImg p.prea {
  width: 65%;
  margin: auto;
}
.effectImg1.img {
  border: 2px solid $border-color-code;
  width: 100%;
  padding-bottom: 12px;
}

label.label.categories_txt.underLine_categories .head-title {
  text-decoration: underline;
  padding: 4px 20px;
  text-decoration-color: #00bc6f;
  text-decoration-style: double;
}
label.label.effects_txt1.animate_effect .head-title {
  text-decoration: underline;
  padding: 4px 20px;
  text-decoration-color: #00bc6f;
  text-decoration-style: double;
}

label.label.effects_txt.underLine_effects .head-title {
  text-decoration: underline;
  padding: 4px 20px;
  text-decoration-color: #00bc6f;
  text-decoration-style: double;
}

label.label.effects_txt1.underLine_effects .head-title {
  text-decoration: underline;
  padding: 4px 20px;
  text-decoration-color: #00bc6f;
  text-decoration-style: double;
}

.underLine_effects1.head-title {
  padding: 4px 20px;
  text-decoration-color: #00bc6f;
  text-decoration-style: double;
}

label.label.categories_txt.underLine_categories .prea {
  text-decoration: none;
}

.categoryImg.img p.store_prea {
  margin: auto;
  display: flex;
  width:65%;
  padding-bottom: 12px;
 
}

p.store_prea {
  margin: auto;
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
}

input.discount-radio-test_1 {
  display: none;
}
.labels.rightside_radio_btn {
  float: right;
  width: 32%;
}
.content_here {
  display: none;
}
.theme-btn {
  background-color: #fff !important;
  color:$font-color-code;
}

button.MuiButton-root.theme-btn {
  color:$font-color-code;
  background-color: #fff !important;
  text-transform: none;
}
.image {
  display: block;
  width: 100%;
  height: auto;
}
.overlay {
  position: absolute;
  top: 0px;
  bottom: 0;
  left: 0px;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
}
.themes_grid img {
  border: 3px solid transparent;
}
.themes_grid img {
  width: 90%;
}
.themes_grid:hover .overlay {
  opacity: 1;
}

#test-1:checked ~ #content-test_1 {
  display: block;
}
#test-2:checked ~ #content-test_2 {
  display: block;
}
label#label-test-2 {
  padding: 0.8em 1em;
  background-color: #9d9d9e;
  padding: 0.8em 1em;
  border-radius: 0 20px 20px 0;
  cursor: pointer;
  color:$font-color-code-white;
}

label#label-test-1 {
  padding: 0.8em 1em;
  background-color: #9d9d9e;
  padding: 0.8em 1em;
  border-radius: 20px 0 0 20px;
  left: 4px;
  position: relative;
  cursor: pointer;
  color:$font-color-code-white;
}
.radio-test_1 {
  display: none;
}

label#label_effects {
  background:$font-background-code-white;
  width: 100%;
  float: left;
  text-align: center;
  height: 120px;
  padding: 6px 7px;
  font-size: 14px;
  letter-spacing: 1px;
}
.categoryImg p {
  font-family: $font-family-head;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;
  color:$font-color-code;
}
.effectImg p {
  font-family: $font-family-head;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;
  color:$font-color-code;
}
.effectImg1 p {
  font-family: $font-family-head;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;
  color:$font-color-code;
}
label div p.MuiTypography-root.MuiTypography-body1.head-title {
  padding-top: 12px;
}
.themes_grid img {
  width: 60%;
}
.effectImg {
  border: 2px solid #c9c9c9;
  width: 100%;
  padding-bottom: 12px;
  cursor: pointer;
}
.categoryImg {
  border: 2px solid #c9c9c9;
  padding-bottom: 12px;
  cursor: pointer;
  width: 100%;
}
.effectImg1 {
  border: 2px solid #c9c9c9;
  padding-bottom: 12px;
  cursor: pointer;
  width: 100%;
}

label#label_categories {
  background:$font-background-code-white;
  width: 100%;
  float: left;
  text-align: center;
  padding: 6px 7px;
  font-size: 14px;
  letter-spacing: 1px;
}
label#label_effects1 {
  background:$font-background-code-white;
  width: 100%;
  float: left;
  text-align: center;
  padding: 6px 7px;
  font-size: 14px;
  letter-spacing: 1px;
}

#coupon_valid_1:checked ~ .labels #label_categories {
  background: #f1f5f9;
  width: 30%;
  color:$font-color-code-white;
  float: left;
  text-align: center;
  height: 120px;
  padding: 6px 7px;
  font-size: 14px;
  letter-spacing: 1px;
}
#coupon_valid_2:checked ~ .labels #label_effects {
  background: #f1f5f9;
  width: 30%;
  color:$font-color-code-white;
  height: 120px;
  float: left;
  text-align: center;
  padding: 6px 7px;
  font-size: 14px;
  letter-spacing: 1px;
}
.themes_grid {
  position: relative;
  width: 100%;
  margin-top: 8px;
}

.text {
  color:$font-color-code-white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
.themeLine {
  height: 362px;
  width: 2px;
  background-color: #e1e1e1;
  float: left;
}
p.prea {
  font-family:$font-family-sub-head;
  font-style: normal;
  font-weight: 400;
  height: 70px;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.1em;
  color:$font-color-code;
  width: 100%;
  margin: 0;
}
.choosePre {
  font-family: $font-family-head;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;
  color: #bebebe;
}
.choosePre.MuiBox-root {
  text-align: center;
  display: flex;
  justify-content: center;
}

.MuiGrid-root.MuiGrid-grid-sm-5.MuiGrid-grid-md-5.MuiGrid-grid-lg-5.css-1tst5ie-MuiGrid-root {
  left: 8px;
  position: relative;
}
.btn_effect {
  justify-content: right;
  align-items: end;
  float: right;
}
.btn_effect button {
  text-transform: capitalize;
  font-family: "Overpass";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  width: 100px;
  text-align: center;
  color:$font-color-code-white;
}
.MuiGrid-root.MuiGrid-item button.setup_btn.theme_btn {
  justify-content: end;
  display: initial;
  align-items: initial;
  position: relative;
  bottom: 0;
  text-transform: capitalize;
  font-family: "Overpass";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color:$font-color-code-white;
}

.head-title {
  text-align: left;
  text-transform:$font-uppercase;
  color:$font-color-code;
}
label.label.categories_txt p {
  text-align: center;
  width: 65%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.effectImg.img p.store_prea {
  width: 65%;
  margin: auto;
}

p.tagdomain {
  font-family:$font-family-sub-head;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.1em;
  color:$font-color-code;
  white-space: nowrap;
}
p.tagdomain span {
  font-style: italic;
  font-size: 12px;
  color:$font-color-code;
  opacity: 0.5;
}
.alertDomain.alertStack
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.MuiAlert-root.MuiAlert-standardSuccess.MuiAlert-standard {
  position: relative;
  left: 0px;
  margin-bottom: 30px;
}

p.btnColor {
  width: 300px;
  padding: 12px 4px;
}

.skipbtnRight {
  float: right;
  width: 210px;
  position: relative;
}
