.arrowBox.MuiBox-root {
  margin: 12px;
}
h6.connectLbl {
font-family: $font-family-head;
font-style: normal;
font-weight: 400;
font-size: 16px; float: left;
line-height: 19px;
width: 100%;
letter-spacing: 0.1em;
color:$font-color-code;
}
button.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButtonBase-root.setup_btn.account-setup.setupConnect {
  background: $font-background-code;
  color: $font-color-code-white;
  font-family: $font-family-head;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;
}
p.MuiTypography-root.MuiTypography-body1.connectSub {
  font-family: $font-family-sub-head;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.1em;
  color: #4b4b4b;
}
p.MuiTypography-root.MuiTypography-body1.connectSub img {
  width: 17px;
  margin: 0px 7px;
}

.connectSub {
  font-family: "Overpass";
  font-style: italic;
  font-weight: 400;
  font-size: 28px;
  line-height: 29px;
  letter-spacing: 0.546875px;
}
.connectLbl {
  font-family: "Overpass";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.703125px;
  color: $font-color-code;
}
.connectSub a {
  color: #4b4b4b;
}
