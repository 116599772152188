.icon_accordian p{
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height:0px;
    letter-spacing: 0.1em; float: left;
    width: 100%;
    color: #000000;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.Mui-expanded.MuiAccordion-gutters.accordion_border.border_none .MuiButtonBase-root .MuiAccordionSummary-expandIconWrapper{
border: 1px solid #FFF;
}

.MuiAccordionSummary-content.Mui-expanded.MuiAccordionSummary-contentGutters h6.MuiTypography-root.MuiTypography-h6.connectShow.connectLbl {
    width: 145px;
    position: absolute;
    right:-18px;
    top: 28px;
font-family: 'Bebas Neue';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
display: flex;
align-items: center;
letter-spacing: 0.2em;
color: #000000;
}
 h6.MuiTypography-root.MuiTypography-h6.connectdetails.connectLbl.css-2ulfj5-MuiTypography-root {
width: 145px;
position: absolute;
right:24px;
top: 35px;
font-family: 'Bebas Neue';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
display: flex;
align-items: center;
letter-spacing: 0.2em;
color: #000000;
}
button.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root.copyUrl:hover{
    background-color: transparent;
}
span.tooltiptext {
position: relative;
padding: 9px 28px;  float: right;
color:#000;
font-family: "Bebas Neue", cursive;
font-style: normal;
font-weight: 400;
font-size: 20px;
}


span.tooltiptext img {
position: absolute;
width: 16px;
top: 15px;
left: 8px;
}
span.copyicon {
border: 1px solid #dfdfdf;
display: grid;
padding: 13px;
border-radius: 5px;
margin: -5px 10px;
position: relative;
}
span.copyicon img {
    width: 14px;
}

input.no-outline {
border-top-style: hidden;
border-right-style: hidden;
border-left-style: hidden;
border-bottom-style: hidden;
font-family: 'Barlow';
font-style: normal;
font-weight: 400;
font-size: 16px;
width:133px;
line-height: 30px;
letter-spacing: 0.1em;
color: #000000;
}
input.no-outline1 {
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: hidden;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    width: 200px;
    line-height: 30px;
    letter-spacing: 0.1em;
    color: #000000;
    }
  
  .no-outline:focus {
    outline: none;
  }
  
  .no-outline1:focus {
    outline: none;
  }
.connectFullbtn {
width: 100%;
float: left;
}
.connectFullbtn .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters{
background: transparent;
float: left;
width: 100%;
box-shadow: none;
}
p.MuiTypography-root.MuiTypography-body1.reflectPre{
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    color: #000000;
    

}

.connectFullbtn.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters.MuiAccordionSummary-expandIconWrapper svg{
    display: block;

}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.Mui-expanded.MuiAccordion-gutters.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
    box-shadow: none!important;
}



.icon_accordian .MuiAccordionSummary-expandIconWrapper.Mui-expanded svg {
    display: block;
}
.icon_accordian .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    background: transparent!important;   
    width: 41px;
    height: 41px;
    border: 1px solid #FFF;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
}

.seeDetails.MuiBox-root {
    float: right;
    width: 100%;
    background-color: transparent!important;
    box-shadow: none;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters.bgNoneAcc{
box-shadow: none;
}
.MuiAccordionDetails-root .domainRecord {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    padding: 12px;
    width: 365px;
}
.MuiGrid-root.MuiGrid-container.MuiGrid-grid-md-8.domainRecord .MuiGrid-root p{
    font-family: "Barlow";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 14px;
    letter-spacing: 0.1em;
    color: #000000;
    white-space: nowrap;
}
.MuiGrid-root.MuiGrid-container.MuiGrid-grid-md-8.domainRecord .MuiGrid-root{
width: 48%;
}


p.MuiTypography-root.MuiTypography-body1.see_Pre{
font-family: 'Barlow';
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 10px;
letter-spacing: 0.05em;   
color: #000000;    
opacity: 0.7;
}
h6.MuiTypography-root.MuiTypography-h6.connectdetails.connectLbl {
    position: absolute;
    right: 0;
    width: 152px;
    top: 35px;
}
p.MuiTypography-root.MuiTypography-body1.see_Pre p {
    font-family: 'Barlow';
}
.MuiAccordionDetails-root p.MuiTypography-root.MuiTypography-body1.see_Pre.css-ahj2mt-MuiTypography-root p {
   font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 10px;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    color: #000000;
    opacity: 0.7;
}

p.MuiTypography-root.MuiTypography-body1.connectDomain p {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height:0px;
    letter-spacing: 0.1em;
    color: #000000;
}
