.logo_Url ::-webkit-input-placeholder {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.1em;
    color: #cacaca;
  }
  .logo_Url :-moz-placeholder {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.1em;
    color: #cacaca;
  }
  .logo_Url ::-moz-placeholder {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.1em;
    color: #cacaca;
  }
  .logo_Url :-ms-input-placeholder {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.1em;
    color: #cacaca;
  }
  .logo_Url label#outlined-helperText-label {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.1em;
    color: #cacaca;
  }