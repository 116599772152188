.userNameHeader h4 {
    font-family: $font-family-head;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 43px;
    text-transform:$font-uppercase;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1em;   
    color:$font-color-code;
    
}
.LogoPart.MuiBox-root .MuiBox-root img {
    width: 64px;
}
p.MuiTypography-root.MuiTypography-body1.headerPre{
    font-family: 'Overpass';
    font-style: normal;
    font-weight: 400;
    font-size:16px!important;
    line-height:24px;
    color: rgba(0, 0, 0, 0.5);
}

.leftBlock {
    float: left;
    width:100%;
    position: relative;
    display: flex;
    justify-content: left;
    align-items: center;
}

.logRight.MuiBox-root {
    float: right;
    position: absolute;
    right: 0;
}
.LogoPart.MuiBox-root {
    float: left;
    width: 80%;
    display: flex;
    justify-content: left;
    align-items: center;
}
.rightBlock {
    width: 25%;
    float: right;
    position: relative;
    display: flex;
    justify-content: right;
    align-items: center;
}
.rightBlock .userNameHeader {
    float: left;
}
.rightBlock .userNameHeader {
    float: left;
}
.rightBlock .userNameHeader h4 {
    margin: 0px 13px; text-decoration: none;
}
.centerBlock span.progressCount {
    margin: 11px 20px;
}
.rightBlock .logout-inner {
    float: right;
    position: absolute;
    right: 0;
}
.leftBlock .userNameButton .stackLeft button {
    margin: 0px 30px;
    border: none;
}
.centerBlock span.progressCount {
float: right;
}
.leftBlock .userNameHeader {
    float: left;
    word-break: break-all;
}

.LogoPart.MuiBox-root div.MuiBox-root {
    float: left;
    width: 115px;
}
.leftBlock .userNameButton {
    float: right;
    position: absolute;
    right: 0;
}
.centerBlock span.MuiLinearProgress-root {
    margin: 0;
}
.centerBlock {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}
.rightBlock {
    width: 20%;
    float: right;
}
.centerBlock span.MuiLinearProgress-root {
    background: #ddd;
    border: 0px solid rgb(45, 13, 13);
    // line-height: 12px;
    // height: 9px;
    position: relative;
    height: 5px !important;
}
.readytogo {
    width: 22%;
    position: relative; left: 12px;
}
.readytogo p{
    float: right;

}
.readytogo img {
    float: left;
    width: 30px;
    top: 8px;
    position: absolute;
}
.MuiLinearProgress-bar {
    border-radius: 0px;
    background-color:$font-background-code!important;
}
.blankRow {
    position: absolute;
    top: 67px;
    left: 15.6666%;
    right: 16.35%;
    bottom: 43px;
    background: transparent;
    height: 6px;
    z-index: 999;
    justify-content: space-between;
    display: flex;
}
.blankRow div {
    width: 10px;
    height: 10px;
    background:$font-background-code-white;
}
.css-9l3hom-MuiLinearProgress-root {
    border-radius: 0 !important;
}
.css-9l3hom-MuiLinearProgress-root .MuiLinearProgress-bar {
    border-radius: 0 !important; 
}
.App header {
    position: fixed;
    width: 100%;
    z-index: 11;
    box-shadow: none;
}
.LogoPart.MuiBox-root div.MuiBox-root img {
width: 90px;
height: 42px;
object-fit: contain;
}