$font-family-head: "Bebas Neue", cursive;
$font-family-sub-head: "Barlow";
$font-color-code:#000000;
$font-background-code:#000000;
$font-color-code-white:#FFFFFF;
$font-background-code-white:#FFFFFF;
$font-placeholder-color-code:#CACACA;
$border-color-code:#000000;
$disabled_btn: #cccccc !important;
$font-uppercase:uppercase; 
