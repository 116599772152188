.ag-cell.ag-cell-not-inline-editing.ag-cell-normal-height.ag-cell-focus.ag-cell-value {
  text-transform:$font-uppercase;
  font-family: "Overpass";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.546875px;
  color:$font-color-code;
}
.ag-theme-alpine {
  position: relative;
  width: auto;
  left: 25px; z-index: 1;
  background:none!important;
}
.ag-cell.ag-cell-not-inline-editing.ag-cell-normal-height.ag-cell-value {
  text-transform:$font-uppercase;
  font-family: "Overpass";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.546875px;
  color:$font-color-code;
}
span.ag-header-cell-text {
  font-family: "Overpass";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.546875px;
  color:$font-color-code;
  opacity: 0.5;
}

.ag-theme-alpine .ag-row-hover {
  background-color: transparent !important;
  background-color: var(--ag-row-hover-color, rgba(33, 150, 243, 0.1));
}

.ag-theme-alpine .ag-header {
  border-bottom: solid 1px #fff !important;
}
.ag-theme-alpine .ag-root-wrapper {
  border: none !important;
}
.ag-header-viewport {
  background: #fff;
}

.tableOrganize,
.ag-root,
.ag-unselectable,
.ag-layout-normal {
  display: inline !important;
}

.ag-theme-alpine .ag-header-cell,
.ag-theme-alpine .ag-header-group-cell {
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-left: 10px;
}
.ag-header-cell-text {
  font-family:$font-family-head!important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  letter-spacing: 0.1em !important;
  text-align: start !important;
  color:$font-color-code!important;
  opacity: 1 !important;
}
