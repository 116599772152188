.alertBox_cls .MuiCollapse-wrapper {
    right: 0;
    position:relative;
    top:20px; float: right;
    width: 45%;
    bottom: 0;    
}
.MuiAlert-icon svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeInherit{
display: none;
}
.alertBox_cls .MuiAlert-message {
    word-break: break-all;
}
.alertBox_cls .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.MuiAlert-root.MuiAlert-standardSuccess.MuiAlert-standard {
background: #55FFB8;
border: 1px solid #55FFB8;
font-family: 'Barlow';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 19px;
display: flex;
align-items: center;
letter-spacing: 0.1em;
color: #000000;

}