header {background:$font-background-code-white!important;}
.header_Bg {
padding-bottom: 8px;
padding-top: 8px;
border-bottom: 1px solid #CDCDCD;
}
header .toggleLeftBar { display: none; }
a.active { background:$font-background-code-white; padding-bottom: 12px; padding-top: 12px!important; }
.leftBar a {  display: block; text-align: left; }

.leftBar a img {
  width: 20px;
  margin: 0px 5px;
}

.leftBar a p {
font-family: $font-family-head;
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 24px;
display: flex;
align-items: center;
letter-spacing: 0.1em;
color:$font-color-code;
margin: 12px;
}
.leftBar a svg{
  float: left;
  position: relative;
  top:12px;
  font-size: 20px;
}
a.navList {
  opacity: 0.5; background-color: transparent;
  pointer-events: none;
}
.leftBar {
  background-color:$font-background-code-white;
   border-right: 1px solid #CDCDCD;
  padding: 20px 0;
  height: 100%;
  position: fixed;
  z-index: 1;
  top:70px;
  left: 0;
  overflow-x: hidden;
  padding-top: 20px;
  z-index: 1;
}
.leftBar a { background:$font-background-code-white;
padding: 2px 5px;
font-family: $font-family-head;
font-style: normal;
font-weight: 400;
position: relative;
font-size: 20px;
line-height: 24px; 
text-transform:$font-uppercase;
display: flex;
align-items: center;
letter-spacing: 0.1em;
color:$font-color-code!important;
}

header.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionStatic.header_Bg {
  box-shadow: none;
}

@media screen and (max-width: 1024px) {
      header .toggleLeftBar {
      display: inline-block;
      cursor: pointer;
    }
    .leftBar { -webkit-transform: translateX(-250px);  transform: translateX(-250px);
      position: absolute;    max-width: 34px;
      width: 100%;-webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;   z-index: 99;
      top: 0; left: 0;  bottom: 0;
      height: auto;
    }
    .leftBar.showBar {-webkit-transform: translateX(0); transform: translateX(0); }
  }

  

