.logo_Url ::-webkit-input-placeholder {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.1em;
  color: $font-placeholder-color-code;
}
.logo_Url :-moz-placeholder {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.1em;
  color: $font-placeholder-color-code;
}
.logo_Url ::-moz-placeholder {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.1em;
  color: $font-placeholder-color-code;
}
.logo_Url :-ms-input-placeholder {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.1em;
  color: $font-placeholder-color-code;
}
.logo_Url label#outlined-helperText-label {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.1em;
  color: $font-placeholder-color-code;
}

.MuiGrid-root.or_grid {
  width: 200px;
}

.MuiGrid-root.or_grid h3 {
  font-family: "Bebas Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;
  color: $font-color-code;
}
.acc_setup_main.upload_txt_main .acc_setup_head {
  width: 100%;
}
.divider_brand {
  width: 60%;
}

.uploadLogo_main {
  #input[type="file"] {
    display: none;
  }
  label.image-upload p {
    margin: 0;
  }
  .btnColor_Picker .colorPickerTxt_1 {
    line-height: 8px;
    font-style: italic;
  }
  .btnColor_Picker {
    position: absolute;
    margin-top: 35px;
    width: 120px;
    text-align: center;
  }
  .label.space_btn_logo {
    margin-top: 0px;
    position: relative;
    top: 20px;
  }

  .label {
    width: 100%;

    display: flex;
    justify-content: center;
  }
  .image-upload {
    background: $font-background-code;
    border: 0.6875px solid rgba(175, 175, 175, 0.98);
    border-radius: 4px;
    margin: auto;
    width: 162px;
    height: 42px;
    font-family: $font-family-head;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.2em;
    color: $font-color-code-white;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-transform: none;
    cursor: pointer;
    padding-bottom: 7px;
    padding-top: 5px;
    span {
      position: relative;
      top: 2px;
      margin: 0px 4px;
      font-family: $font-family-head;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.2em;
      color: $font-color-code-white;
    }
    img {
      width: 18px;
    }
  }
  .img-holder-active img:hover {
    cursor: pointer;
    opacity: 1;
  }
  .img-holder {
    margin: auto;

    h3 {
      text-align: center;
      justify-content: center;
      align-items: center;
      display: flex;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      color: #a4a4a4;
      font-size: 15px;
      line-height: 20px;
      text-transform: none;
    }
    img {
      width: 100%;
    }
  }
}

.MuiGrid-root.MuiGrid-item.MuiGrid-grid-md-6.txtpicker_txt p {
  font-family: $font-family-sub-head;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.1em;
  color: #939393;
}

.replay {
  width: 30px;
  position: relative;
  height: 30px;
  cursor: pointer;
  border-radius: 3px;
  img {
    display: flex;
    justify-items: center;
    align-items: center;
    position: absolute;
    top: 0px;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 16px;
    text-align: center;
  }
}

.logo_Url {
  width: 90%;
}
.uploadlogoWrap {
  display: flex;
  align-items: center;
}

#input1[type="file"] {
  display: none;
}
.colorPicker {
  float: left;
  position: relative;
  input {
    margin: 0;
    width: 90px;
    height: 90px;
    cursor: pointer;
    padding: 0;
    border-radius: 20px;
    border: 1px solid $border-color-code;
  }
}

input#standard-basic-page {
  height: 30px;
}
.colorPicker {
  float: left;
  position: relative;
  width: 100%;

  input {
    margin: 0;
    width: 60px;
    height: 60px;
    cursor: pointer;
    padding: 0;
    border: 1px solid $border-color-code;
    border-radius: 20px;
  }
}
.btnColor p {
  padding: 0px;
  margin: 8px;
}

.btnColor {
  background: #fafafa;
  border: 1px solid #dddddd;
  border-radius: 5px;
  float: left;
  position: relative;
  background: #fafafa;
  border: 1px solid #dddddd;
  border-radius: 5px;
  width: 275px;
  font-family: $font-family-sub-head;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #434343;

  input {
    margin: 0;
    cursor: pointer;
    padding: 0;
    border: none;
    border-radius: 20px;
  }
}

button.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButtonBase-root.setup_btn.account-setup.css-1o4rz3q-MuiButtonBase-root-MuiButton-root {
  background: transparent;
  box-shadow: none;
}

img.usr {
  width: 30px;
  cursor: pointer;
}

.BtnGroupBrand {
  width: 100%;
  margin-top: 15px;
  line-height: 22px;
}

.colorPickerTxt.MuiBox-root {
  font-family: $font-family-sub-head;
  font-style: normal;
  font-weight: 500;
  padding-bottom: 2px;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.1em;
  color: $font-color-code;
}
.hex {
  font-family: $font-family-sub-head;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  padding-top: 2px;
  line-height: 17px;
  letter-spacing: 0.1em;
  color: $font-color-code;
}

.uploadLogo_main .img-holder img {
  width: 70%;
  height: auto;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  bottom: 0;
  margin: auto;
  left: 0;
  align-items: center;
}
.uploadLogo_main .img-holder img {
  width: 60%;
}
.ag-cell.ag-cell-not-inline-editing.ag-cell-normal-height.ag-cell-value {
  text-transform: $font-uppercase;
  font-family: "Overpass";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 43px !important;
  letter-spacing: 0.546875px;
  color: $font-color-code;
}

.colorPicker2 {
  width: 100%;
  input {
    width: 140px;
    height: 27px;
    border: none;
    background: transparent;
    padding: 0px;
    cursor: pointer;
  }
  input::placeholder {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 500;
    padding-bottom: 2px;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.1em;
    color: #000000;
  }
}
.inputMargin {
  border: 1px solid #000000 !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  outline: none;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  padding-bottom: 2px;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.1em;
  color: #000000;
  cursor: auto !important;
}

.inputMargin ::placeholder {
  font-family: "Barlow", cursive;
  font-style: normal;
  font-weight: 500;
  padding-bottom: 2px;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.1em;
  color: #000000 !important;
}

input.borderGray {
  border: 1px solid #dddddd;
}
input.inputBoxButtonText {
  border: 1px solid #dddddd;
}

button.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root.resetButton {
  font-family: $font-family-head;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;
  color: $font-color-code;
  float: left;
}

.ButtonText {
  width: 80%;
}
.ButtonText.resetButtonBox {
  width: 100%;
}
.ButtonText.resetButtonBox
  button.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root.resetButton.css-1v8o3mx-MuiButtonBase-root-MuiButton-root {
  position: relative;
  top: 0px;
  text-transform: capitalize;
}

button.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButtonBase-root.Mui-disabled.setup_btn.account-setup.css-1o4rz3q-MuiButtonBase-root-MuiButton-root {
  background-color: transparent;
  color: $font-color-code;
  box-shadow: none;
}

.txtpicker_txt {
  text-align: left;
}
.acc_setup_main.upload_txt_main {
  padding-bottom: 12px;
}
.acc_setup_main.upload_txt_main h3 {
  font-family: $font-family-head;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  color: $font-color-code;
}

.uploadlogoWrap.spacewrap {
  margin-bottom: 10px;
}

.MuiGrid-root.MuiGrid-item.MuiGrid-grid-md-2.txtpicker_txt h3.hrdftrColor {
  font-size: 13px;
  font-family: "Overpass";
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.390625px;
  color: #999999;
}

h3.MuiTypography-root.MuiTypography-h3.textColor {
  font-family: "Overpass";
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.390625px;
  color: #999999;
}

.colorPicker input {
  margin: 0;
  width: 95px;
  height: 95px;
  cursor: pointer;
  padding: 0;
  background: transparent;
  border-radius: 20px;
  border: 1px solid $border-color-code;
}

.replay img:hover {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.img-holder-active svg {
  position: absolute;
  top: 44px;
}
.img-holder-active img {
  width: 100%;
  display: flex;
  object-fit: contain;
  height: 120px;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.uploadLogo_main .image-upload img.editingImg {
  width: 18px;
  position: relative;
  margin: -5px 0px;
}
.img-holder-active svg {
  position: absolute;
  cursor: pointer;
  right: 0px;
  top: 2px;
  background: $font-background-code-white;
  border-radius: 38px;
}

.img-holder-active {
  position: relative;
  padding: 5px;
  height: 120px;
  border: 2px dashed $border-color-code;
  border-radius: 5px;
  width: 140px;
  margin: auto;
}
.uploadLogo_main .img-holder {
  border: 4px dashed #cecece;
  border-radius: 11px;
  position: relative;
  padding: 5px;
  height: 120px;
  width: 140px;
}

.uploadLogo_main {
  float: left;
  margin-top: 12px;
  position: relative;
  width: 225px;
}

.acc_setup_main .acc_cred_grid {
  text-transform: $font-uppercase;
  font-weight: 300;
  letter-spacing: 0.7px;
  justify-content: center;
  font-size: 14px;
  display: flex;
}

h3.MuiTypography-root.MuiTypography-h3.or {
  justify-content: center;
}
.uploadlogoWrap
  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-md-3.css-ot7hnh-MuiGrid-root {
  justify-content: center;
  display: flex;
}
.btnColor_Picker {
  position: absolute;
  margin-top: 40px !important;
  width: 125px;
  margin: auto;
  text-align: center;
  line-height: 5px;
}
p.colorPickerTxt_1 {
  font-style: italic;
}

.or {
  font-family: $font-family-head;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;
  color: $font-color-code;
}
p.MuiFormHelperText-root {
  font-family: $font-family-sub-head;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.1em;
  color: $font-color-code;
}

// .MuiInput-root.MuiInput-underline input.MuiInput-input.MuiInputBase-input {
//   font-family: $font-family-sub-head;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 20px;
//   line-height: 24px;
//   letter-spacing: 0.1em;
//   color: $font-color-code;
//   border: 1px solid #bababa;
//   border-top: none;
//   border-left: none;
//   border-right: none;
// }
.acc_setup_main.upload_txt_main
  .uploadlogoWrap
  .MuiGrid-item
  .uploadLogo_main
  .acc_setup_head
  h3 {
  text-align: center;
}
.uploadLogo_main .img-holder svg {
  float: right;
  position: absolute;
  right: 0;
  margin: 6px;
  color: $font-color-code;
  cursor: pointer;
}
.resetButton {
  margin-top: 30px !important;
}
