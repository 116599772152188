@import "../../common.scss";

.fullHeight {
  height: 100vh;
}
.logoResolution {
  width: get-vw(112.3px);
  height: get-vw(58px);
  margin-left: get-vw(30px);
  margin-top: get-vw(20px);
}

.active_btn {
  padding: 0px !important;
  background-color: $font-background-code;
  margin-top: 38px !important;
  text-transform: capitalize !important;
  border: 1px solid $border-color-code !important;
  border-radius: 3px !important;
  font-family: $font-family-head;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  letter-spacing: 0.1em !important;
  color: $font-color-code-white !important;
}
button.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-fullWidth.MuiButtonBase-root.Mui-disabled.active_btn.loadingActive {
  height: 55px;
  background-color: $font-background-code;
}
button.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-fullWidth.MuiButtonBase-root.active_btn.loadingActive {
  height: 55px;
  background-color: $font-background-code;
}

button.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-fullWidth.MuiButtonBase-root.Mui-disabled.active_btn {
  background-color: $font-background-code;
}
.welcome {
  font-family: "Bebas Neue";
  font-style: normal;
  font-weight: 400;
  font-size: get-font-vw(36px);
  line-height: get-font-vw(43px);
  letter-spacing: 0.1em;
  color: #000000;
}
.subTopic {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  font-size: get-font-vw(12px);
  line-height: get-font-vw(14px);
  letter-spacing: 0.1em;
  color: #000000;
}
.containerWidth {
  max-width: get-vw(500px);
  margin-left: get-vw(170px);
  margin-top: get-vw(240px);
}
.containerWidthOtp {
  max-width: get-vw(570px);
  margin-left: get-vw(170px);
  margin-top: get-vw(240px);
}
.loginPageInput {
  margin-top: get-vw(35px);
}
.loginPageInput ::placeholder {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  font-size: get-font-vw(20px);
  line-height: get-font-vw(24px);
  letter-spacing: 0.1em;
  color: #dfdfdf;
}
.subTopic {
  margin-top: get-vw(5px);
}
.buttoncontainer {
  margin-top: get-vw(70px);
  text-align: end;
}
.active_btn_loadingActive {
  width: get-vw(198px) !important;
  height: get-vw(55px) !important;
  background-color: $font-background-code !important;
  color: #fff !important;
  cursor: pointer !important;
}
.unactive_btn_loadingActive {
  width: get-vw(198px) !important;
  height: get-vw(55px) !important;
  background-color: $disabled_btn !important;
  color: #fff !important;
  cursor: pointer !important;
}

p.loadbtn i {
  padding: 8px;
}
.authForm .Mui-disabled {
  background: $disabled_btn;
  color: #fff !important;
  line-height: 15px !important;
  border: 1px #cccccc solid !important;
}
.checkEmail {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  font-size: get-font-vw(18px);
  line-height: get-font-vw(22px);
  letter-spacing: 0.1em;
  color: #000000;
  margin-top: get-vw(10px);
}
.otpWrapper {
  margin-top: get-vw(40px);
}
// .login_left_img img {
//   width: 70%;
//   float: left;
// }
// .login_top_icon {
//   position: absolute;
//   right: -127px;
//   z-index: -1;
//   top: -13px;
// }
// .login_top_icon img {
//   width: 70%;
//   top: 45px;
//   position: relative;
//   right: -40px;
// }

// .login_bottom_icon {
//   position: absolute;
//   left: -73px;
//   z-index: -1;
//   bottom: 30px;
// }
// .login_bottom_icon img {
//   width: 70%;
// }

// .active_btn {
//   padding: 0px !important;
//   background-color: $active_btn;
//   margin-top: 38px !important;
//   text-transform: capitalize !important;
//   border: 1px solid #000000 !important;
//   border-radius: 3px !important;
//   font-family: $font-family;
//   font-style: normal !important;
//   font-weight: 400 !important;
//   font-size: 20px !important;
//   letter-spacing: 0.1em !important;
//   color: #ffffff !important;
// }
// button.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-fullWidth.MuiButtonBase-root.Mui-disabled.active_btn.loadingActive{
// height:55px;
// }
// button.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-fullWidth.MuiButtonBase-root.active_btn.loadingActive {
//   height:55px;
// }

// p.loadbtn i {
//   padding: 4px;
//   margin: -1px 6px;
//   position: relative;
// }
// p.resendLoad {
//   position: absolute;
//   right: 70px;
//   top: -16px;
// }

.otpcls input {
  color: $font-color-code;
}
.otpcls input {
  border: 1px solid #000000 !important;
}
.resendWrapper {
  margin-top: get-vw(30px);
  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  font-size: get-font-vw(20px);
  line-height: get-font-vw(30px);
  letter-spacing: -0.165px;
  color: #969696;
}
.resend {
  color: #000000;
  margin-left: 10px;
  cursor: pointer;
  font-weight: 700;
  text-decoration: underline;
}
.errorMessage {
  font-family: "Bebas Neue";
  font-style: normal;
  font-weight: 400;
  font-size: get-font-vw(20px);
  line-height: get-font-vw(24px);
  letter-spacing: 2px;
  color: #ff3a00;
  text-transform: capitalize;
  margin-top: get-vw(40px);
  white-space: nowrap;
}

// .otpcls input:focus {
//   background: #DEDEDE!important; border-radius: 5px;
//   font-family: 'Overpass';
//   font-style: normal;
//   font-weight: 400;
//   font-size: 24px;
//   line-height: 30px;
//   display: flex;
//   align-items: center;
//   text-align: center;
//   letter-spacing: 0.703125px;
//   color: rgba(0, 0, 0, 0.3);
// }

.otpbdr input {
  border: 1px solid red !important;
}
.authForm .Mui-disabled {
  background: $disabled_btn;
  color: $font-color-code-white !important;
  line-height: 15px !important;
  border: 1px #cccccc solid !important;
}

.authForm .MuiPaper-root {
  min-height: 330px;
  border-radius: initial;
  background: #ffffff;
  border: 1px solid $border-color-code;
  border-radius: 4px;
  border-bottom: 4px solid $border-color-code;
}
.lineHeight {
  height: 230px;
  width: 100%;
  float: left;
}
.lineHeight p {
  color: $font-color-code;
  font-size: 16px;
  font-style: normal;
  font-family: $font-family-sub-head;
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: 19px;
}

i.fa.fa-circle-o-notch.fa-spin {
  color: $font-color-code-white;
}
.authForm h2 {
  font-weight: 700;
  color: $font-color-code;
  font-family: $font-family-head;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: 43px;
  padding-bottom: 25px;
}

@media screen and (max-width: 768px) {
  .login_left_img img {
    width: 100%;
  }
  .login_top_icon {
    position: absolute;
    right: -22px;
    z-index: -1;
    top: 0px;
    width: 58%;
  }
  .login_bottom_icon {
    position: absolute;
    left: -37px;
    z-index: -1;
    bottom: 0px;
    width: 58%;
  }
}

p.MuiTypography-root.MuiTypography-h1.validate {
  font-family: "Overpass";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  align-items: center;
  letter-spacing: 0.703125px;
  color: #ff3a00;
}
.validate {
  float: left;
  width: 100%;
  margin: auto;
  text-align: center;
  display: flex;
  position: relative;
  margin-top: 10px;
  left: 6px;
}
p.MuiTypography-root.MuiTypography-body2.MuiTypography-alignCenter.MuiTypography-gutterBottom {
  position: relative;
}

.valid img {
  width: 19px;
  top: -1px;
  position: absolute;
  left: -21px;
}

// i.fa.fa-circle-o-notch.fa-spin {
//   color:#FFF;
// }
// .authForm h2 {
//   font-weight: 700;
//   color: #000;
//   font-family: Bebas Neue;
//   font-size: 36px;
//   font-style: normal;
//   font-weight: 400;
//   letter-spacing: .1em;
//   line-height: 43px;
//   padding-bottom: 25px;
// }

// @media screen and (max-width: 768px) {
//   .login_left_img img {
//     width: 100%;
//   }
//   .login_top_icon {
//     position: absolute;
//     right: -22px;
//     z-index: -1;
//     top: 0px;
//     width: 58%;
//   }
//   .login_bottom_icon {
//     position: absolute;
//     left: -37px;
//     z-index: -1;
//     bottom: 0px;
//     width: 58%;
//   }
// }

.loginPageInput label {
  font-family: $font-family-sub-head !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 24px !important;
  z-index: -1;
  color: #cacaca !important;
}
