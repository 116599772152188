.acc_setup_head.write_icon{
    width: 50%;
    position: relative;
}

.acc_setup_head.write_icon .arrowBox.MuiBox-root {
    position: absolute;
    right: 0;
    padding: 0;
    margin: 0;
}

.arrowBox.MuiBox-root img {
    cursor: pointer;
}